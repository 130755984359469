import { Slot } from './slot.model';

export const SLOTS: Slot[] = [
  {
    id: 11,
    name: '09-10',
    cartId: 1,
    location: 'Dronning L',
    group: 'NA',
    perticipants: new Array('Per', 'Poul'),
  },
  {
    id: 12,
    name: '10-11',
    cartId: 1,
    location: 'Kgs. Nytorv',
    group: 'NA',
    perticipants: new Array('Per', 'Poul'),
  },
  {
    id: 13,
    name: '11-12',
    cartId: 1,
    location: 'Dronning L',
    group: 'NA',
    perticipants: new Array('Per', 'Poul'),
  },
  {
    id: 14,
    name: '12-13',
    cartId: 1,
    location: 'Dronning L',
    group: '1',
    perticipants: new Array('Per', 'Poul'),
  },
  {
    id: 15,
    name: '13-14',
    cartId: 1,
    location: 'Dronning L',
    group: '2',
    perticipants: new Array('Gurli', 'Thormod'),
  },
  {
    id: 16,
    name: '14-15',
    cartId: 1,
    location: 'Dronning L',
    group: '3',
    perticipants: new Array('Grit', 'Githa'),
  },
  {
    id: 17,
    name: '15-16',
    cartId: 1,
    location: 'Dronning L',
    group: '4',
    perticipants: new Array('Nemo', 'Momo'),
  },
  {
    id: 18,
    name: '16-17',
    cartId: 1,
    location: 'Dronning L',
    group: '5',
    perticipants: new Array('Lotte', 'Line'),
  },
];
