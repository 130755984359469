<div class="mat-elevation-z8">
    <h4>Apply filter to search for a watch</h4>
    <mat-form-field>
        <mat-label>Vogne</mat-label>
        <mat-select [(ngModel)]="selectedValue" name="food" (selectionChange)="applyFilter($event)">
            <mat-option *ngFor="let version of versions" [value]="version" >
                {{version}}
            </mat-option>
        </mat-select>
    </mat-form-field>
    <mat-form-field>
        <mat-label>Filter</mat-label>
        <input matInput (keyup)="applyFilter($event)" placeholder="Ex. ium">
    </mat-form-field>
    <button mat-button (click)="confirmDialog('What would you like...?')">Open dialog</button>
    <table mat-table class="full-width-table" matSort aria-label="Elements">
        <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Tid</th>
            <td mat-cell *matCellDef="let row">{{row.name}}</td>
        </ng-container>
        <ng-container matColumnDef="name" >
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Who is on the watch</th>
            <td mat-cell *matCellDef="let row" style="white-space:pre-wrap">{{row.perticipants?row.perticipants : row.pub_1+ '&nbsp;-&nbsp;' +row.pub_2}}</td>
        </ng-container>
        <ng-container matColumnDef="location">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Where is the cart</th>
            <td mat-cell *matCellDef="let row">{{row.location}}</td>
        </ng-container>
        <ng-container matColumnDef="group">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Grp.</th>
            <td mat-cell *matCellDef="let row">{{row.group}}</td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row routerLink="/detail/{{row.id}}" *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    <mat-paginator #paginator [length]="dataSource?.data.length" [pageIndex]="0" [pageSize]="50"
        [pageSizeOptions]="[25, 50, 100, 250]">
    </mat-paginator>
</div>
